import React, { useState } from 'react';
import './Header.css';  // Import Header-specific styles
import logo from '../../assets/icons/logo.jpg';  // Import the logo image
import { Link } from 'react-router-dom';

const Header = () => {
  // State to control the mobile menu visibility and icon state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the menu visibility and icon state
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>

      {/* Hamburger Icon for mobile */}
      <div
        className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`} 
        onClick={toggleMenu}
      >
        <div className={`line ${isMenuOpen ? 'line1' : ''}`}></div>
        <div className={`line ${isMenuOpen ? 'line2' : ''}`}></div>
        <div className={`line ${isMenuOpen ? 'line3' : ''}`}></div>
      </div>

      {/* Navigation Menu */}
      <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
        <ul className="nav-list">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
