// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  background-image: linear-gradient(to right, #30cfd0 0%, #330867 100%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
    flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.css"],"names":[],"mappings":"AAAA;EACE,qEAAqE;EACrE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".app {\n  background-image: linear-gradient(to right, #30cfd0 0%, #330867 100%);\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\nmain {\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
