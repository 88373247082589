import React, { useContext, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { MyContext } from "../../context/AppContext";
import './Slider.css'

const Slider = ({
  slides,
  autoplay = true,
  navigation = true,
  pagination = true,
  loop = true,
}) => {
  const swiperRef = useRef(null);
  const { activeSlideIndex, setActiveSlideIndex } = useContext(MyContext);
  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.activeIndex;
    setActiveSlideIndex(currentIndex);
  };

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.on("slideChange", () => handleSlideChange(swiper));
    }
  });

  return (
    <div className="mr-14">
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{
          clickable: true
        }}
        autoplay={true}
        slidesPerView={1}
        keyboard={true}
        spaceBetween={-250}
        ref={swiperRef}
        initialSlide={activeSlideIndex}
        breakpoints={{
          480: {
            slidesPerView: 1, //
            spaceBetween: -150,
          },
          768: {
            spaceBetween: -250,
          },
          1024: {
            spaceBetween: -250,
          },
          1440: {
            spaceBetween: -250,
          },
        }}
        className="pl-[90px] pt-6"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative">
              <img
                src={slide.img}
                alt={slide.alt || `Slide ${index + 1}`}
                className="object-cover shadow-xl"
                loading="lazy"
              />
              
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
