import React, { useContext, useState, useEffect } from "react";
import mountainImg from "../assets/images/mountainImg.jpg";
import beachImg from "../assets/images/beachImg.jpg";
import mountainTrekImg from "../assets/images/mountainTrekImg.jpg";
import corpTravelImg from "../assets/icons/corp-travel.svg";
import tourImg from "../assets/icons/tour.svg";
import eventImg from "../assets/icons/event.svg";
import Slider from "../components/Slider/Slider";
import "../assets/styles/Home.css";
import { MyContext } from "../context/AppContext";

const whyChooseOptions = [
  {
    id: 1,
    title: "Tailored Travel Solutions",
    description:
      "Whether you're planning a corporate trip or a family getaway, our personalized approach ensures every detail is crafted for you.",
  },
  {
    id: 2,
    title: "Seamless Travel Management",
    description:
      "From air ticketing to accommodations, we handle all logistics so you can focus on what matters.",
  },
  {
    id: 3,
    title: "Unparalleled Expertise",
    description:
      "With years of experience in travel and event management, we guarantee hassle-free experiences and exceptional service.",
  },
];

const exploreData = [
  {
    title: "Explore Mountains",
    country: "Mountains",
  },
  {
    title: "Explore Beaches",
    country: "Beaches",
  },
  {
    title: "Explore Trekking",
    country: "Trekking",
  },
];

const servicesOffered = [
  {
    id: 1,
    title: "Corporate Travel Management",
    description:
      "From booking flights to managing accommodations, we handle all your corporate travel needs with ease.",
    icon: corpTravelImg,
  },
  {
    id: 2,
    title: "Customized Tours",
    description:
      "Exclusive experiences tailored for couples, families, and groups to create lasting memories.",
    icon: tourImg,
  },
  {
    id: 3,
    title: "Event Management",
    description:
      "End-to-end solutions for conferences, meetings, and exhibitions with seamless execution.",
    icon: eventImg,
  },
];

function Home() {
  const { activeSlideIndex } = useContext(MyContext);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("why-choose-us");
      const rect = section.getBoundingClientRect();
      const topVisible = rect.top <= window.innerHeight * 0.8;

      setIsVisible(topVisible);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSliderClass = (activeSlideIndex) => {
    const { country, title } = exploreData[activeSlideIndex] || {};

    if (country && title) {
      return (
        <div className="head">
          <h1 className="text-[70px] text-white font-Monteserrat font-[800] leading-[70px]">
            Explore <br />
            <span className={`country ${country}`} data-country={country}>
              {country}
            </span>
            <p className="font-Poppins text-[14px]">Tailored Solutions for Business and Leisure.</p>
          </h1>
        </div>
      );
    }

    return null;
  };

  const handleSliderContainerClass = (activeSlideIndex) => {
    switch (activeSlideIndex) {
      case 0:
        return "first-slide-bg";
      case 1:
        return "second-slide-bg";
      case 2:
        return "third-slide-bg";
      default:
        return "first-slide-bg";
    }
  };

  const classChange = handleSliderClass(activeSlideIndex);

  const bgClassChange = handleSliderContainerClass(activeSlideIndex);

  const places = [
    {
      name: "Mountains",
      img: mountainImg,
      alt: "slider_img1",
    },
    {
      name: "Beaches",
      img: beachImg,
      alt: "slider_img2",
    },
    {
      name: "Mountain Trekking",
      img: mountainTrekImg,
      alt: "slider_img3",
    },
  ];

  return (
    <div className="home">
      {/* Heading Section */}
      <section className={`${bgClassChange} overflow-hidden`}>
        <div className="flex items-center main-container-wrapper">
          <div className="w-1/2 pl-12 head-wrapper">
            {classChange}
            <div className="mt-[40px] flex flex-col items-start gap-5 btn-wrapper">
              <button className="border-2 border-[#00A1B9] text-[20px] px-6 py-2 font-semibold text-white hover:text-[#00A1B9]">
                Explore Corporate Travel Solutions
              </button>
              <button className="border-2 border-[#00A1B9] text-[20px] px-6 py-2 font-semibold text-white hover:text-[#00A1B9]">
                Plan Your Customized Tour
              </button>
            </div>
          </div>

          <div className="w-1/2 flex flex-col items-end justify-end relative mt-7 mb-7 slider-wrapper">
            <div className="slider-main-container">
              <Slider slides={places} />
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="why-choose-us" className="bg-white py-20 text-center px-6">
        <h2 className={`text-4xl font-bold text-gray-800 mb-6 ${isVisible ? "animate-fade-in-down" : ""}`}>
          Why Choose Us?
        </h2>
        <p className={`text-lg text-gray-600 mb-12 ${isVisible ? "animate-fade-in-down" : ""}`}>
          We offer state-of-the-art tools to empower your professional journey.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-7xl mx-auto">
          {whyChooseOptions.map((option) => (
            <div
              key={option.id}
              className="bg-gray-100 p-6 rounded-lg shadow-lg cursor-pointer transform hover:scale-105 transition-transform duration-300"
            >
              <h3 className="text-2xl font-semibold text-indigo-800 mb-4">
                {option.title}
              </h3>
              <p className="text-gray-600">{option.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Dynamic Cards/Services Section */}
      <section className="bg-gradient-to-r from-indigo-900 to-indigo-700 text-white py-16 px-6">
        <div className="max-w-7xl mx-auto text-center">
          {/* Section Header */}
          <h2 className="text-4xl font-bold mb-6">Services We Offer</h2>
          <p className="text-lg mb-12">
            Explore our tailored solutions designed to meet all your travel and
            event management needs.
          </p>

          {/* Dynamic Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {servicesOffered.map((service) => (
              <div
                key={service.id}
                className="bg-white text-indigo-900 rounded-lg shadow-lg p-6 hover:shadow-xl transform hover:-translate-y-2 transition duration-300"
              >
                <div className="mb-4 flex justify-center">
                  <img
                    src={service.icon}
                    alt={service.title}
                    className="h-16 w-16 object-contain"
                  />
                </div>

                <h3 className="text-2xl font-semibold mb-2">{service.title}</h3>

                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
