// src/components/Footer/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // Import the Footer styles

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section footer-logo">
          <h2 className="footer-logo-text">Forward Meetings</h2>
          <p className="footer-description">
            Making the world a better place, for the people by the people.
          </p>
        </div>

        <div className="footer-section footer-links">
          <h3 className="footer-links-heading">Quick Links</h3>
          <ul>
            <li>
              <Link to="/" className="footer-link">Home</Link>
            </li>
            <li>
              <Link to="/about" className="footer-link">About</Link>
            </li>
            <li>
              <Link to="/services" className="footer-link">Services</Link>
            </li>
            <li>
              <Link to="/contact" className="footer-link">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="footer-section footer-socials">
          <h3 className="footer-socials-heading">Follow Us</h3>
          <div className="social-icons">
            <a href="#" className="social-icon">FB</a>
            <a href="#" className="social-icon">TW</a>
            <a href="#" className="social-icon">IG</a>
            <a href="#" className="social-icon">LN</a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© 2024 Forward Mettings. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
