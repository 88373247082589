// src/pages/About.js
import React from "react";
import { Link } from 'react-router-dom';

function About() {
  return (
    <section className="bg-gradient-to-b from-indigo-50 to-white text-gray-800">
      {/* Hero Section */}
      <div className="relative bg-indigo-800 text-white py-16 px-6 text-center">
        <h1 className="text-5xl font-bold mb-4 font-montserrat">About Us</h1>
        <p className="text-lg font-poppins max-w-3xl mx-auto">
          Creating travel experiences as unique as you are.
        </p>
      </div>

      {/* Our Story Section */}
      <div className="py-16 px-6 max-w-7xl mx-auto text-center md:text-left">
        <h2 className="text-4xl font-bold text-indigo-800 mb-6 font-montserrat">
          Our Story
        </h2>
        <p className="text-lg text-gray-600 font-poppins leading-relaxed">
          At Forward Meetings, we believe in creating travel experiences that
          are as unique as you are. With a rich legacy of expertise in travel
          management, corporate solutions, and event services, we are passionate
          about taking the stress out of planning and adding a touch of
          excellence to every journey.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="p-6 bg-indigo-100 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-indigo-800 mb-4 font-montserrat">
              Corporate Travel
            </h3>
            <p className="text-gray-700 font-poppins">
              Organizing flawless corporate travel with unparalleled efficiency.
            </p>
          </div>
          <div className="p-6 bg-indigo-100 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-indigo-800 mb-4 font-montserrat">
              Tour Packages
            </h3>
            <p className="text-gray-700 font-poppins">
              Curating bespoke packages for families, couples, and solo
              adventurers.
            </p>
          </div>
        </div>
      </div>

      {/* Mission and Vision Section */}
      <div className="bg-indigo-50 py-16 px-6">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-4xl font-bold text-indigo-800 mb-6 font-montserrat">
              Our Mission
            </h2>
            <p className="text-lg text-gray-600 font-poppins leading-relaxed">
              To provide seamless, tailored travel solutions that inspire trust,
              comfort, and unforgettable memories.
            </p>
          </div>
          <div>
            <h2 className="text-4xl font-bold text-indigo-800 mb-6 font-montserrat">
              Our Vision
            </h2>
            <p className="text-lg text-gray-600 font-poppins leading-relaxed">
              To be a leading name in corporate travel and personalized tour
              management, making every journey extraordinary.
            </p>
          </div>
        </div>
      </div>

      {/* Core Values Section */}
      <div className="py-16 px-6 bg-white">
        <h2 className="text-4xl font-bold text-indigo-800 text-center mb-6 font-montserrat">
          Our Core Values
        </h2>
        <ul className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto">
          <li className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-md text-center">
            <div className="mb-4">
              <svg
                className="w-12 h-12 text-indigo-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h3 className="text-2xl font-semibold text-indigo-800 mb-2 font-montserrat">
              Commitment to Excellence
            </h3>
            <p className="text-gray-600 font-poppins text-center">
              We go the extra mile to deliver superior service.
            </p>
          </li>
          <li className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-md text-center">
            <div className="mb-4">
              <svg
                className="w-12 h-12 text-indigo-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h3 className="text-2xl font-semibold text-indigo-800 mb-2 font-montserrat">
              Client-Centric Approach
            </h3>
            <p className="text-gray-600 font-poppins text-center">
              Your needs and preferences always come first.
            </p>
          </li>
          <li className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-md text-center">
            <div className="mb-4">
              <svg
                className="w-12 h-12 text-indigo-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h3 className="text-2xl font-semibold text-indigo-800 mb-2 font-montserrat">
              Reliability and Trust
            </h3>
            <p className="text-gray-600 font-poppins text-center">
              We ensure timely, cost-effective, and reliable solutions for every
              traveler.
            </p>
          </li>
        </ul>
      </div>

      {/* CTA Section */}
      <div className="bg-indigo-800 text-white py-12 text-center">
        <h2 className="text-4xl font-bold mb-4 font-montserrat">
          Ready to Plan Your Next Journey?
        </h2>
        <p className="text-lg font-poppins mb-6">
          Discover how we can make your next trip extraordinary.
        </p>
        <button className="bg-white text-indigo-800 px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-gray-200 transition">
          <Link to="/contact">Contact Us</Link>
        </button>
      </div>
    </section>
  );
}

export default About;
