// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    body {
      font-size: var(--font-size-small);
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    h2 {
      font-size: 1.25rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/responsive.css"],"names":[],"mappings":"AAAA;IACI;MACE,iCAAiC;IACnC;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,kBAAkB;IACpB;EACF","sourcesContent":["@media (max-width: 768px) {\n    body {\n      font-size: var(--font-size-small);\n    }\n  \n    h1 {\n      font-size: 1.5rem;\n    }\n  \n    h2 {\n      font-size: 1.25rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
