// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --background-color: #ffffff;
    --text-color: #333333;
    --font-size-small: 14px;
    --font-size-medium: 16px;
    --font-size-large: 18px;
    --spacing-small: 8px;
    --spacing-medium: 16px;
    --spacing-large: 24px;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,2BAA2B;IAC3B,qBAAqB;IACrB,uBAAuB;IACvB,wBAAwB;IACxB,uBAAuB;IACvB,oBAAoB;IACpB,sBAAsB;IACtB,qBAAqB;EACvB","sourcesContent":[":root {\n    --primary-color: #3498db;\n    --secondary-color: #2ecc71;\n    --background-color: #ffffff;\n    --text-color: #333333;\n    --font-size-small: 14px;\n    --font-size-medium: 16px;\n    --font-size-large: 18px;\n    --spacing-small: 8px;\n    --spacing-medium: 16px;\n    --spacing-large: 24px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
