// src/components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom'; // Used to render nested routes
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Layout.css'

function Layout() {
  return (
    <div className='app'>
      <Header />
      <main>
        <Outlet /> {/* Nested routes will be rendered here */}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
